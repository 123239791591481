<template>
  <div id="layout-default" class="min-h-100dvh">
    <navbar class="fixed top-0 w-full z-navbar" />
    <main class="pt-16 relative">
      <line-banner-with-client />
      <slot class="page-content"></slot>

      <simple-content-viewer
        v-model:show="modalStore.show"
        v-bind="{ ...modalStore.$state, onCancel: modalStore.onCancel, onConfirm: modalStore.onConfirm }"
      ></simple-content-viewer>
    </main>
    <addendum />

    <global-alert />
    <login-modal />

    <!-- <json-ld-organization />
          <json-ld-website /> -->
  </div>
</template>

<script setup>
import Navbar from '@/components/common/navbar.vue'
import Addendum from '@/components/common/addendum.vue'
import GlobalAlert from '@/components/common/global-alert.vue'
import SimpleContentViewer from '@/components/common/simple-content-viewer.vue'
import LineBannerWithClient from '@/components/social/line-banner-with-client.vue'
import LoginModal from '@/components/login/login-modal.vue'

import { useModalStore } from '@/store/modal'
import { useRegionStore } from '@/store/region'

let controller = new AbortController()

const { locale } = useI18n()

// fetch global data
const regionStore = useRegionStore()
const modalStore = useModalStore()

const { regionId } = useRegion()

await useAsyncData('regionList', regionStore.fetchRegionList)
await useAsyncData(
  `region_${regionId.value}`,
  async () => {
    if (typeof regionId.value !== 'number') return {}

    controller.abort()
    controller = new AbortController()

    const region = await regionStore.fetchRegion(regionId.value, { signal: controller.signal })
    return region
  },
  { watch: [regionId, locale] }
)
</script>

<style lang="scss" scoped></style>
